<template>
  <div class="verificationProtocol">
    <div class="verificationProtocol-box">
        <h1 class="title">《企业名称禁限用规则》</h1>
        <p class="p3">第一章 总则</p>
        <p class="p2">第一条为规范企业名称审核行为，建立、完善企业名称比对系统，为申请人提供更加便利的企业名称登记、核准服务，根据《公司法》《企业法人登记管理条例》《公司登记管理条例》《企业名称登记管理规定》《企业名称登记管理实施办法》和工商总局有关规范性文件等制定本规则。</p>
        <p class="p2">第二条本规则适用于企业名称登记、核准有关业务。企业名称审核人员依据本规则对企业名称申请是否存在有关禁限用内容进行审查，按照有关规定作出核准或者驳回的决定。</p>
        <p class="p2">第三条企业登记机关可以依据本规则建立、完善企业名称比对系统，为申请人提供企业名称筛查服务。企业名称自主申报改革试点地区可以参照本规则，建立、完善比对、申报系统，为申请人提供自主申报、自负其责的登记服务。</p>
        <p class="p3">第二章 禁止性规则</p>
        <p class="p2">第四条 企业名称不得与同一企业登记机关已登记注册、核准的同行业企业名称相同。</p>
        <p class="p2">以下情形适用于本条款规定：</p>
        <p class="p2">(一)与同一登记机关已登记、或者已核准但尚未登记且仍在有效期内、或者已申请尚未核准的同行业企业名称相同;</p>
        <p class="p2">(二)与办理注销登记未满1年的同行业企业名称相同;</p>
        <p class="p2">(三)与同一登记机关企业变更名称未满1年的原同行业名称相同;</p>
        <p class="p2">(四)与被撤销设立登记和被吊销营业执照尚未办理注销登记的同行业企业名称相同。</p>
        <p class="p2">第五条 企业名称不得含有有损于国家、社会公共利益的内容和文字。</p>
        <p class="p2">以下情形适用于本条款规定：</p>
        <p class="p2">(一)有消极或不良政治影响的。如“支那”“黑太阳”“大地主”等。</p>
        <p class="p2">(二)宣扬恐怖主义、分裂主义和极端主义的。如“九一一”“东突”“占中”等。</p>
        <p class="p2">(三)带有殖民文化色彩，有损民族尊严和伤害人民感情的。如“大东亚”“大和”“福尔摩萨”等。</p>
        <p class="p2">(四)带有种族、民族、性别等歧视倾向的。如“黑鬼”等。</p>
        <p class="p2">(五)含有封建文化糟粕、违背社会良好风尚或不尊重民族风俗习惯的。如“鬼都”“妻妾成群”等。</p>
        <p class="p2">(六)涉及毒品、淫秽、色情、暴力、赌博的。如“海洛因”“推牌九”等。</p>
        <p class="p2">第六条 企业名称不得含有可能对公众造成欺骗或者误解的内容和文字。</p>
        <p class="p2">以下情形适用于本条款规定：</p>
        <p class="p2">(一)含有党和国家领导人、老一辈革命家、知名烈士和知名模范的姓名的。如“董存瑞”“雷锋”等。</p>
        <p class="p2">(二)含有非法组织名称或者反动政治人物、公众熟知的反面人物的姓名的。如“汪精卫”“秦桧”等。</p>
        <p class="p2">(三)含有宗教组织名称或带有显著宗教色彩的。如“基督教”“佛教”“伊斯兰教”等。</p>
        <p class="p2">第七条 企业名称不得含有外国国家(地区)名称、国际组织名称。</p>
        <p class="p2">第八条 企业名称不得含有政党名称、党政军机关名称、群团组织名称、社会组织名称及部队番号。</p>
        <p class="p2">第九条 企业名称应当使用符合国家规范的汉字，不得使用外文、字母和阿拉伯数字。</p>
        <p class="p2">第六条 企业名称不得含有可能对公众造成欺骗或者误解的内容和文字。</p>
        <p class="p2">第十条 企业名称不得含有其他法律、行政法规规定禁止的内容和文字。</p>
        <p class="p2">第十一条企业名称应当由行政区划、字号、行业、组织形式依次组成。企业名称中的行政区划是本企业所在地县级以上行政区划的名称或地名。市辖区的名称不能单独用作企业名称中的行政区划。</p>
        <p class="p2">第十二条 企业名称中的字号应当由2个以上的符合国家规范的汉字组成，行政区划、行业、组织形式不得用作字号。</p>
        <p class="p2">第十三条企业应当根据其主营业务，依照国家行业分类标准划分的类别，在企业名称中标明所属行业或者经营特点。国家法律、法规以及国务院决定等对企业名称中的行业有特殊要求的，应当在企业名称中标明。不得在企业名称中标示国家法律、法规以及国务院决定等禁止经营的行业。</p>
        <p class="p2">第十四条企业应当根据其组织结构或者责任形式在名称中标明符合国家法律、法规以及国务院决定规定的组织形式，不得使用与其组织结构或者责任形式不一致的组织形式。</p>
        <p class="p3">第三章 限制性规则</p>
        <p class="p2">第十五条 企业名称不得与同一企业登记机关已登记注册、核准的同行业企业名称近似，但有投资关系的除外。</p>
        <p class="p2">第十六条 企业法人名称中不得含有其他非营利法人的名称，但有投资关系或者经该法人授权，且使用该法人简称或者特定称谓的除外。该法人的简称或者特定称谓有其他含义或者指向不确定的，可以不经授权。</p>
        <p class="p2">第十七条企业名称中不得含有另一个企业名称，但有投资关系或者经该企业授权，且使用该企业的简称或者特定称谓的除外。该企业的简称或者特定称谓有其他含义或者指向不确定的，可以不经授权。</p>
        <p class="p2">第十八条 企业名称不得明示或者暗示为非营利组织或者超出企业设立的目的，但有其他含义或者法律、法规以及国务院决定另有规定的除外。</p>
        <p class="p2">第十九条除国务院决定设立的企业外，企业名称不得冠以“中国”“中华”“全国”“国家”“国际”等字样;在企业名称中间使用“中国”“中华”“全国”“国家”“国际”等字样的，该字样应是行业的限定语;使用外国(地区)出资企业字号的外商独资企业、外方控股的外商投资企业，可以在名称中间使用“(中国)”字样。以上三类企业名称需经工商总局核准，但在企业名称中间使用“国际”字样的除外。</p>
        <p class="p2">第二十条企业名称应当冠以企业所在地省(包括自治区、直辖市)或者市(包括州、地、盟)或者县(包括市辖区、自治县、旗)行政区划名称，但符合以下条件之一、经工商总局核准的，企业名称可以不含企业所在地行政区划：</p>
        <p class="p2">(一)国务院批准的;</p>
        <p class="p2">(二)工商总局登记注册的;</p>
        <p class="p2">(三)注册资本(或注册资金)不少于5000万元人民币的;</p>
        <p class="p2">(四)工商总局另有规定的。</p>
        <p class="p2">第二十一条市辖区名称与市行政区划连用的企业名称，由市企业登记机关核准。省、市、县行政区划连用的企业名称，由最高级别行政区的企业登记机关核准。上级企业登记机关可以授权下级机关核准应当由本机关核准的企业名称。</p>
        <p class="p2">第二十二条 企业名称的字号应当由字、词或其组合构成，不得使用语句、句群和段落，但具有显著识别性或有其他含义的短句除外。</p>
        <p class="p2">第二十三条 企业名称的字号不得含有“国家级”“最高级”“最佳”等带有误导性内容和文字，但有其他含义或者作部分使用、且字号整体有其他含义的除外。</p>
        <p class="p2">第二十四条企业名称的字号不得以外国国家(地区)所属辖区、城市名称及其简称、特定称谓作字号，但有其他含义或者作部分使用、且字号整体具有其他含义的除外。</p>
        <p class="p2">第二十五条 行政区划不得用作字号，但县以上行政区划的地名具有其他含义的除外。</p>
        <p class="p2">第二十六条 企业名称不得以职业、职位、学位、职称、军衔、警衔等及其简称、特定称谓作字号，但有其他含义或者作部分使用、且字号整体有其他含义的除外。</p>
        <p class="p2">第二十七条 企业不得使用工商总局曾经给予驰名商标保护的规范汉字作同行业企业名称的字号，但已经取得该驰名商标持有人授权的除外。</p>
        <p class="p2">第二十八条 企业名称中的行业不得使用与主营业务不一致的用语表述，符合以下条件的可以不使用国民经济行业类别用语表述企业所从事的行业：</p>
        <p class="p2">(一)企业经济活动性质分别属于国民经济行业5个以上大类;</p>
        <p class="p2">(二)企业注册资本(或注册资金)1亿元以上或者是企业集团的母公司;</p>
        <p class="p2">(三)与同一企业登记机关登记、核准的同类别企业名称中的字号不相同。</p>
        <p class="p2">第二十九条 法律、法规、国务院决定以及工商总局规章、规范性文件对企业名称的行业表述有特别规定的从其规定。</p>
        <p class="p3">第四章 附则</p>
        <p class="p2">第三十条 地方企业登记机关可以根据地方性法规、政府规定，细化禁限用内容。</p>
        <p class="p2">第三十一条 农民专业合作社、个体工商户和非法人分支机构(营业单位)名称的登记、核准，参照本规则执行。</p>
        <p class="p2">第三十二条 本规则根据相关法律、法规以及国务院决定等的调整适时调整并公布。</p>
        <p class="p2">第三十三条 本规则由工商总局解释。</p>
        <h1 class="title">企业名称相同相近比对规则</h1>
        <p class="p2">第一条为进一步推进企业名称登记管理改革，建立、完善企业名称比对系统，为申请人提供高效比对服务，依据《企业名称登记管理规定》《企业名称登记管理实施办法》《工商总局关于提高登记效率积极推进企业名称登记管理改革的意见》(工商企注字〔2017〕54号)等制定本规则。</p>
        <p class="p2">第二条本规则适用于企业登记机关利用信息化技术，建立、完善企业名称比对系统，为申请人申请企业名称提供比对服务。企业登记机关应当将比对结果以在线网页等方式呈现给申请人，供其参考、选择。</p>
        <p class="p2">第三条 申请人提交的企业名称登记、核准申请有下列情形之一的，比对系统提示为企业名称相同：</p>
        <p class="p2">(一)与同一企业登记机关已登记、核准的企业名称完全相同。</p>
        <p class="p2">(二)与同一企业登记机关已登记、核准的企业名称行政区划、字号、行业和组织形式排列顺序不同但文字相同。如：北京红光酒业发展有限公司与红光(北京)酒业发展有限公司。</p>
        <p class="p2">(三)与同一企业登记机关已登记、核准的企业名称字号、行业文字相同但行政区划或者组织形式不同。如：北京红光酒业有限公司与红光酒业有限公司;北京红光酒业有限公司与北京红光酒厂。</p>
        <p class="p2">第四条 申请人提交的企业名称登记、核准申请有下列情形之一的，比对系统提示为企业名称相近：</p>
        <p class="p2">(一)与同一企业登记机关已登记、核准的同行业企业名称字号相同，行业表述不同但含义相同。如：万青地产有限公司与万青房地产有限公司、万青置业有限公司。</p>
        <p class="p2">(二)与同一企业登记机关已登记、核准的同行业企业名称字号的字音相同，行业表述相同或者行业表述不同但内容相同。如：北京牛栏山酒业有限公司与北京牛兰山酒业有限公司、北京牛蓝山白酒有限公司。</p>
        <p class="p2">(三)字号包含同一企业登记机关已登记、核准同行业企业名称字号或者被其包含，行业表述相同或者行业表述不同但内容相同。如：北京阿里巴巴网络科技有限公司与北京阿里巴巴巴巴网络科技有限公司、北京阿里巴巴在线信息科技有限公司。</p>
        <p class="p2">(四)字号与同一企业登记机关已登记、核准同行业企业名称字号部分字音相同，行业表述相同或者行业表述不同但内容相同。如：北京阿里巴巴科技有限公司与北京马云阿理巴巴科技有限公司、北京阿理巴巴金控技术有限公司。</p>
        <p class="p2">(五)不含行业表述或者以实业、发展等不使用国民经济行业分类用语表述行业的，包含或者被包含同一企业登记机关已登记、核准的同类别企业名称的字号，或者其字号的字音相同，或者其包含、被包含的部分字音相同。如：北京牛兰山有限公司与北京金牛栏山有限公司;北京全聚德有限公司与北京荃巨得有限公司、北京宏荃聚德实业有限公司。</p>
        <p class="p2">第五条申请人通过比对系统查询申请企业名称时，拟申请的企业名称与同一企业登记机关已登记、核准的企业名称相同的，列出相同的企业名称，提示该申请不能通过;拟申请的企业名称与同一企业登记机关已登记、核准的企业名称相近的，列出相近的企业名称清单，提示该申请可以通过，但存在审核不予核准的可能，存在虽然核准，但在使用中可能面临侵权纠纷，甚至以不适宜的企业名称被强制变更的风险。</p>
        <p class="p2">第六条 地方企业登记机关可以根据地方政府要求、改革需要和技术条件等，细化比对规则，不断提高比对智能化服务水平。</p>
        <p class="p2">第七条 农民专业合作社、个体工商户名称和非法人分支机构(营业单位)的比对，参照本规则执行。</p>
        <p class="p2">第八条 本规则由工商总局解释。</p>

    </div>

  </div>
</template>

<script>
export default {
  name: 'verificationProtocol',
  components: {

  },
  data(){
      return{

      }
  },
  watch:{

  },
  methods:{

  }
}

</script>

<style scoped>
.verificationProtocol{
  background-color: #fcfcfc;
}
  .verificationProtocol-box{
      width: 800px;
      margin: 20px auto 20px;
  }
  .verificationProtocol-box>p{
    margin-bottom: 20px;
    color: #333333;
  }
  .title{
    text-align: center;
    font-size: 20px;
    color: #333333;
    margin-bottom: 20px;
  }
  .p1{
      text-indent: 2em;
      font-weight: 600;
  }
  .p2{
    text-indent: 2em;
  }
  .p3{
    font-weight: 600;
  }
</style>
